<template>
  <div id="members-list">
    <v-overlay :value="OverlayLoadingFlag">
      <div class="text-center">
        <p>
          Please wait, we are processing your request. Loading time may vary
          based on your internet connection speed.
        </p>
        <p></p>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0">
          <v-form ref="form2" v-model="valid1" lazy-validation id="createForm">
            <v-row wrap>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Area</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="AreaCodeOptionsLoading"
                  :items="AreaCodeOptions"
                  :rules="AreaCodeRules"
                  v-model="AreaCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Zone</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span>Region</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="RegionCodeOptionsLoading"
                  :items="RegionCodeOptions"
                  :rules="RegionCodeRules"
                  v-model="RegionCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span>Lom</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  :rules="LomCodeRules"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Year</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="YearCodeOptionsLoading"
                  :items="YearCodeOptions"
                  :rules="YearCodeRules"
                  v-model="YearCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span>Member Type</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="MemberTypeOptions"
                  :rules="MemberTypeRules"
                  v-model="MemberType"
                  label="Member Type"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  color="#ff7f50"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Search</v-btn
                >
                <v-btn
                  @click.prevent="resetForm"
                  color="green"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Clear</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="DownloadFlag"
                color="info"
                @click.prevent="
                  generateExcel(
                    tableData1,
                    ExcelFields,
                    ExcelFileName,
                    'My Worksheet'
                  )
                "
                class="mr-4"
                >Download</v-btn
              >
            </v-col>
            <p></p>
          </v-row>
          <br />
          <h3>Members List</h3>
          <br />
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>{{ tableData1.length }} Records found.</h4>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ProfileUpdateTxt="{ item }">
                  <v-chip :color="item.ProfileUpdateColor" draggable dark>{{
                    item.ProfileUpdateTxt
                  }}</v-chip>
                </template>

                <template v-slot:item.PhototUpdateTxt="{ item }">
                  <v-chip :color="item.PhototUpdateColor" draggable dark>{{
                    item.PhototUpdateTxt
                  }}</v-chip>
                </template>
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.MemberImagePath="{ item }">
                  <img width="100" height="100" :src="item.MemberImagePath" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="editAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      LoadingFlag: false,
      uploadPercentage: 0,
      previewRecordPrompt: false,

      GenerateMrfButtonFlag: false,
      UploadMrfButtonFlag: false,

      MrfDetail: {},
      UploadMrfFlag: false,
      UploadMrfPageTitle: "",

      PageTitle: "",
      PageDescription: "",

      alert: {},
      selected: {},

      valid1: true,
      OverlayLoadingFlag: false,

      JciYearCode: "",

      YearCodeRules: [(v) => !!v || "Year is required"],
      YearCode: "",
      YearCodeOptions: [
        { value: "", text: "--Select Year--" },
        { value: 14, text: "2024" },
        { value: 13, text: "2023" },
      ],
      YearCodeOptionsLoading: false,

      AreaCodeRules: [(v) => !!v || "Area is required"],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      RegionCodeRules: [(v) => !!v || "Region is required"],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      MemberTypeRules: [(v) => !!v || "Member Type is required"],
      MemberType: "",
      MemberTypeOptions: [
        { value: 1, text: "JCI Member" },
        { value: 9, text: "Junior JCI Member" },
        { value: 2, text: "Associate Members" },
      ],

      LomCode: "",
      LomCodeRules: [(v) => !!v || "Lom is required"],
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      ExcelDownloadFlag: false,
      ExcelLoadingFlag: false,
      ExcelFileFlag: false,

      Modules: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,
      RoleId: "",

      PageInfo: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        // RoleId = 4;
        this.GenerateMrfButtonFlag = RoleId == 2 ? true : false;
        this.UploadMrfButtonFlag = RoleId == 2 ? true : false;

        this.pageData();
      }
    },
    AreaCodeOptions: function () {
      console.log("watch AreaCodeOptions");
      this.AreaCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    RegionCodeOptions: function () {
      console.log("watch RegionCodeOptions");
      this.RegionCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
    YearCode: function () {
      console.log("watch YearCode");
    },
    AreaCode: function () {
      console.log("watch AreaCode");
      this.tableData1 = [];
      this.ZoneCode = "";
      this.RegionCode = "";
      this.LomCode = "";
      this.ZoneCodeOptions = [];
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];
      this.getZoneCodeOptions();
    },
    ZoneCode: function () {
      console.log("watch ZoneCode");
      this.tableData1 = [];
      this.RegionCode = "";
      this.LomCode = "";
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];
      this.getRegionCodeOptions();
    },
    RegionCode: function () {
      console.log("watch RegionCode");
      this.tableData1 = [];
      this.LomCode = "";
      this.LomCodeOptions = [];
      this.selected1 = [];
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
      this.getLomCodeOptions();
    },
    LomCode: function () {
      console.log("watch LomCode");
      this.selected1 = [];
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
    },
    MemberType: function () {
      console.log("watch MemberType");
      this.selected1 = [];
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "reports",
        Action: "list_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getAreaCodeOptions();
    },
    resetForm() {
      this.selected1 = [];
      this.tableData1 = [];
      this.tableColumns1 = [];
      this.tableOptions1 = [];
      this.YearCode = "";
      this.AreaCode = "";
      this.ZoneCode = "";
      this.RegionCode = "";
      this.LomCode = "";
      this.MemberType = "";
      this.YearHalf = "";
    },
    searchForm() {
      console.log("searchForm called");

      this.selected1 = [];
      this.tableData1 = [];

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var YearCode = this.YearCode;
        var AreaCode = this.AreaCode;
        var ZoneCode = this.ZoneCode;
        var RegionCode = this.RegionCode;
        var LomCode = this.LomCode;
        var MemberType = this.MemberType;
        var YearHalf = "Second";
        console.log(
          "YearCode=" +
            YearCode +
            ", AreaCode=" +
            AreaCode +
            "ZoneCode=" +
            ZoneCode +
            "RegionCode=" +
            RegionCode +
            "LomCode=" +
            LomCode +
            "MemberType=" +
            MemberType +
            ", YearHalf=" +
            YearHalf
        );

        if (
          YearCode != "" &&
          AreaCode != "" &&
          ZoneCode != "" &&
          RegionCode != "" &&
          LomCode !== "" &&
          MemberType !== "" &&
          YearHalf != ""
        ) {
          this.OverlayLoadingFlag = true;
          this.LoadingFlag = true;
          var server_url = companyConfig.apiURL;
          var token = this.$session.get("token");
          token = token == (null || undefined) ? 0 : token;
          var add_url = server_url + "api/member-dues/member-lists";
          var upload = {
            UserInterface: 2,
            YearCode: YearCode,
            AreaCode: AreaCode,
            ZoneCode: ZoneCode,
            RegionCode: RegionCode,
            LomCode: LomCode,
            MemberType: MemberType,
            YearHalf: YearHalf,
          };
          console.log("upload=" + JSON.stringify(upload));
          console.log(
            "server_url=" +
              server_url +
              ", token=" +
              token +
              ", add_url=" +
              add_url
          );

          const thisIns = this;
          var output = "";
          var records = "";
          var table_content = [];
          var flag = 0;

          thisIns.ProgessStart = 1;

          this.$http({
            url: add_url,
            method: "POST",
            data: upload,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
            .then(function (response) {
              console.log("response=" + JSON.stringify(response));

              thisIns.ProgessStart = 0;
              thisIns.OverlayLoadingFlag = false;
              thisIns.LoadingFlag = false;

              output = response.data.output;
              flag = response.data.flag;
              table_content = response.data.table_content;
              console.log("output=" + output + ", flag=" + flag);

              records = response.data.records;
              console.log({ records });

              var options = table_content.TableOptions;

              thisIns.tableColumns1 = table_content.TableHeader;
              thisIns.tableOptions1 = options;

              if (flag == 1) {
                thisIns.tableData1 = records;
                thisIns.DownloadFlag = options.DownloadFlag;
                thisIns.ExcelFileName = options.ExcelFileName;
                thisIns.ExcelFields = options.ExcelFields;
                thisIns.toast("success", output);
              } else {
                console.log("error=" + output);
                thisIns.toast("error", output);
              }
            })
            .catch(function (error) {
              console.log("error=" + error);
            });
        } else {
          var message = "";
          var message = "Please fill the required fileds.";
          console.log("error=" + message);
          this.toast("error", message);
        }
      } else {
        var message = "Please fill the required fileds.";
        console.log("error=" + message);
        this.toast("error", message);
      }
    },
    getAreaCodeOptions() {
      console.log("getAreaCodeOptions called");
      this.AreaCodeOptionsLoading = true;
      var selectbox1_source = "AreaCode";
      var selectbox1_destination = "AreaCodeOptions";
      var selectbox1_url = "api/year-wise-lom/area-options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        YearCode: this.CurrentYearId,
        ReportId: 4,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.CurrentYearId;
      var areaCode = this.AreaCode;
      console.log("yearCode=" + yearCode + ", areaCode=" + areaCode);
      if (yearCode != "" && areaCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 6,
          YearCode: yearCode,
          AreaCode: areaCode,
          ReportId: 4,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");

      var YearCode = this.CurrentYearId;
      var AreaCode = this.AreaCode;
      var ZoneCode = this.ZoneCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", AreaCode=" +
          AreaCode +
          ", ZoneCode=" +
          ZoneCode
      );
      if (YearCode != "" && AreaCode != "" && ZoneCode != "") {
        this.RegionCode = "";
        this.RegionCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "RegionCode";
        var selectbox1_destination = "RegionCodeOptions";
        var selectbox1_url = "api/year-wise-lom/region-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          AreaCode: AreaCode,
          ZoneCode: ZoneCode,
          ReportId: 4,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");

      var YearCode = this.CurrentYearId;
      var ZoneCode = this.ZoneCode;
      var RegionCode = this.RegionCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", ZoneCode=" +
          ZoneCode +
          ", RegionCode=" +
          RegionCode
      );
      if (YearCode != "" && ZoneCode != "" && RegionCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 4,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          RegionCode: RegionCode,
          ReportId: 4,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        if (RegionCode == "") {
          message += "Region should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    hideUploadMrfPrompt() {
      console.log("hideUploadMrfPrompt called");
      this.UploadMrfFlag = false;
      this.MrfDetail = {};
      this.searchForm();
    },
    previewData(tr) {
      console.log("previewData called");
      var message = "";
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });
      if (n1 > 0) {
        var FilePath = tr.FilePath;
        console.log({ FilePath });
        var MonthName = tr.MonthName;
        console.log({ MonthName });
        if (FilePath != "") {
          this.previewRecordPrompt = true;
          this.DocumentFilePath = FilePath;
          this.PreviewPageTitle = MonthName + " MRF Preview";
        } else {
          message = "File should not be empty.";
          this.toast("error", message);
        }
      } else {
        message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

#members-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>